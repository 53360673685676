.imgStylee {
  border-radius: 80% !important;
  width: 65px;
  height: 65px;
  border-color: black;
  /* margin-right: 30px; */

}
.linkButton
{
    background-color: #246bd6;
    margin-right: 2.85rem;
    border: #246bd6;
    width: 90%;
}
.imgStyleeFullWidth {
  border-radius: 80% !important;
  width: 100px;
  height: 100px;
  border-color: black;
}
.width25{
  width: 25%;
  display: flex;

}
.rowHeight{
  height: 100%;
}
.rowHeightMobile{
  height: 100% !important;
}
.logoImgStyle{
  display: flex;
  justify-content: center;
  align-items: center;
  height: 42px;
}
@media screen and (max-width: 400px) {
.publicLink {
    height: 100% !important;
    max-width: 390px;
    width: 100%;
  }
}
@media screen and (max-width: 768px) {
  body .imgStylee {
    border-radius: 80% !important;
    width: 100px;
    height: 100px;
    border-color: black;
  }
}
.usernameStyle {
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  text-align: center;
  color: #246bd6;

}
.linksStyles {
  background-color: #246bd6;
  color: burlywood;
  width: 100%;
  height: 100%;
  border-radius: 5px;
}
.linksStyless {
  background-color: #246bd6;
  color: burlywood;
  width: 65%;
  height: 100%;
  border-radius: 5px;
}
@media screen and (max-width: 768px) {
  body .linksStyles {
    background-color: #246bd6;
    color: burlywood;
    width: 100%;
    height: 100%;
    border-radius: 5px;
  }
  body .linksStyles {
    background-color: #246bd6;
    color: burlywood;
    width: 90%;
    height: 100%;
    border-radius: 5px;
  }
}

.linksFbStyle {
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 18px;
  text-align: center;
  color: #ffffff;
 padding-top: 5px !important;
}
.width25{
  width: 25%;
  display: flex;

}
.aLink
{
    display: flex;
    justify-content: center;
    /* margin-left: %; */
    /* margin-right: 35%; */
}
.linksInstaStyle {
  background: linear-gradient(
      0deg,
      rgba(255, 220, 128, 0.3),
      rgba(255, 220, 128, 0.3)
    ),
    linear-gradient(270deg, #e1306c 18.07%, rgba(64, 93, 230, 0) 91.57%),
    linear-gradient(180deg, #833ab4 0%, rgba(131, 58, 180, 0) 100%), #405de6;
  border-radius: 4px;
  text-align: center;
  color: #ffffff;
  padding: 5%;
}
.iconStyle {
  width: 30px !important;
  height: 25px !important;
}
.scrollableDiv1 {
  overflow: auto;
  position: absolute;
  top: 70px;
  width: 320px;
  left: 0;
  right: 0;
  margin: 0 auto;
  height: 390px;
}

.publicLink {
  height: 100% !important;
  max-width: 680px;
  width: 100%;
}

.scrollableDiv1::-webkit-scrollbar {
  display: none;
  /* for Chrome, Safari, and Opera */
}
.marginRtStyle {
  /* margin-right: 30% !important; */
}

.socialIconDiv {
  width: 45% !important;
  margin-left: 10px;
}

@media screen and (max-width: 768px) {
  body .socialIconDiv {
    width: 75% !important;
  }
}

.socialIcon {
  height: 25px;
  width: 25px;
  margin-right: 10px
}
.width25{
  width: 35%;
}


@media screen and (max-width: 768px) {
  body .socialIconFullWidth {
    height: 40px;
    width: 40px;
    margin-right: 10px
  }
}

.socialIconFullWidth {
  height: 40px;
  width: 40px;
  margin-right: 10px
}


.icon {
  width: 15px !important;
  height: 15px !important;
}
.iconMbl {
  width: 20px !important;
  height: 20px !important;
}
.width25{
  width: 35%;
}

@media screen and (max-width: 768px) {
  body .socialLinksWithoutFullWidth {
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 100%;
    margin-left: 2%;
  }
}
.socialLinksWithWidth {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 60%;
  margin-left: 23%;
}

.socialLinksWithoutFullWidth {
  display: flex;
  flex-direction: row;
  /* flex-direction: column; */
  /* align-items: center; */
  justify-content: center;
  width: 100%;
}
.width25{
  width: 90%;
  display: flex;
  align-items: center;
}

.link-text {
  color: #ffffff;
  width: 100% !important;
}