.subscrbeStyle {
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 36px;
  line-height: 48px;
  text-align: center;
  color: rgba(0, 0, 0, 0.6);
}
.subscribeFont
{
    font-size: 13px;
    font-weight: 500;
}

.mainBackground
{
  background-color:rgba(25, 221, 255, 0.1);
height: 100vh;
}
.paymentStyle {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 17px;
  text-align: left;
  color: rgba(0, 0, 0, 0.6);
  padding-top: 5px;
}
.time {
    font-style: normal;
    font-weight: bold;
    font-size: 20px !important;
    line-height: 48px;
    text-align: center;
    color: #246bd6 !important;
  }
.underlineText
{
    text-decoration: underline;
}
.cardStyle {
  background: #ffffff;
  box-shadow: 0px 10px 20px 15px rgba(119, 122, 127, 0.1);
  border-radius: 12px;
  margin-top: 30px;
}

.boxStyle {
  background: rgba(128, 107, 231, 0.1);
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.companiesStyle {
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: -0.291429px;
  color: rgba(0, 0, 0, 0.6);
}
.price {
  font-style: normal;
  font-weight: bold;
  font-size: 40px;
  line-height: 48px;
  text-align: center;
  color: #806be7;
}

.cardImageStyle {
  border-style: ridge;
  border-radius: 30px;
  background: #f4f4f5;
  height: 50px;
  border: 1px solid #ced4da;
  padding: 5px 0.75rem 0rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  text-align: center;
  color: #495057;
}

.boxSubscribeMobile {
    background: rgba(128, 107, 231, 0.1);
    border-radius: 8px;
    display: flex;
    /* flex-direction: column; */
    justify-content: center;
    align-items: center;
    width: 100% !important;
  }

.priceStyleSubscribe {
    font-style: normal;
    font-size: 20px;
    line-height: 28px;
    text-align: center;
    color: #246bd6 !important;
    padding-top: 20px !important;
  }

.cardNumberStyle {
  border-style: ridge;
  border-radius: 30px;
  background: #f4f4f5;
  height: 55px;
  border: 1px solid #ced4da;
  padding: 5px 0.75rem 0rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  text-align: center;
  color: #495057;
  width: 100%;
}
.termsStyle {
  font-style: normal;
  font-weight: normal;
  /* font-size: 13px; */
  line-height: 16px;
  text-align: center;
  color: rgba(0, 0, 0, 0.6);
}

.subscribeButton {
  background-color: #246bd6;
  border-radius: 30px;
  width: 100%;
  padding-top: 15px;
  padding-bottom: 15px;
}

.subscribeImageStyle {
  height: 100%;
  width: 100%;
}
@media (max-width: 768px) {
  .boxSize {
    width: 100% !important;
  }
  .companyStyle {
    display: flex;
    justify-content: center;
  }
  .marginLeftStyle {
    margin-left: 5%;
  }
  .scrollClass {
    -ms-overflow-style: none;
    scrollbar-width: none;
  }
  .scrollClass::-webkit-scrollbar {
    display: none;
  }
}
@media (max-width: 375px) {
  .boxSize {
    width: 100% !important;
  }
  .companyStyle {
    display: flex;
    justify-content: center;
  }
  .marginLeftStyle {
    margin-left: 5%;
  }
  .subscribeButton {
    margin-bottom: 5% !important;
  }
}

.width65 {
  width: 65%;
}