.linkStyle {
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 24px;
  /* identical to box height, or 133% */

  letter-spacing: 0.04em;

  color: #19ddff !important;

  background-color: white;
  border: none;
}
.socialLinkStyle {
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 24px;
  /* identical to box height, or 133% */

  letter-spacing: 0.04em;
  background-color: white;
  border: none;
  color: #246bd6 !important;
}
.cryptoStyle {
  font-family: Roboto;
  font-style: normal;
  font-weight: 300;
  font-size: 36px;
  line-height: 48px;
  /* identical to box height, or 133% */

  letter-spacing: 0.04em;
  color: #806be7;
}
.colr {
  color: #246bd6;
}
.urlStyle {
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 24px;
  /* identical to box height, or 133% */

  letter-spacing: 0.04em;

  color: #246bd6;
}
.shareStyle {
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  /* identical to box height, or 129% */

  text-align: center;
  background-color: #246bd6;
  border: none;

  color: #ffffff;
}
.marginRightt {
  margin-left: -25%;
}
.current {
  color: #246bd6;
}
.marginLftStyle {
  margin-left: 4%;
}

.navbarBgColor {
  background-color: #ffffff;
}