.componentHeight {
  height: 100vh;
}
.mainBackground
{
    background-color: #246BD61A;
    height: 100vh;
}

.signupButtonStyles {
  color: white;
  border-radius: 5px;
  width: 100%;
  padding-top: 10px;
  padding-bottom: 10px;
  background-color: #246bd6;
  border: white;
  float: right;
  margin-top: 10px;
  margin-right: 10px;
}

.signupButtonStylesMobile {
    color: white;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    width: 100% !important;
    padding-top: 10px;
    padding-bottom: 10px;
    background-color: #246bd6;
    border: white;
    /* float: right; */
    margin-top: 10px;
  }
.signInCointanerStyle {
  padding-top: 50px;
}
.signIn {
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 36px;
  line-height: 48px;
  text-align: center;
  color: rgba(0, 0, 0, 0.6);
}
.labelStyle {
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: rgba(0, 0, 0, 0.6);
}
.inputFieldStyle {
  border-style: ridge;
  border-radius: 30px;
  background: #f4f4f5;
  height: 60px !important;
  width: 100%;
  border: 1px solid #ced4da;
  padding: 10px 0.75rem 0rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  /* text-align: center; */
  color: #495057;
}
.eyeIconStyle {
    position: absolute;
    margin-top: -5%;
    right: 25% !important;
    font-size: 30px;
    opacity: 0.3;
  }
  .eyeIconStyleMobile
  {
        position: absolute;
        margin-top: -10%;
        left: 85% !important;
        font-size: 30px;
        opacity: 0.3;
  }
.inputFieldStyleMobile {
    border-style: ridge;
    border-radius: 30px;
    background: #f4f4f5;
    height: 50px !important;
    width: 100%;
    border: 1px solid #ced4da;
    padding: 10px 0.75rem 0rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    /* text-align: center; */
    color: #495057;
  }
.submitButtonStyleLogin {
  background-color: #246bd6 !important;
  border-radius: 30px;
  width: 100% !important;
  height: 55px;
  border: none;
  color: white;
  font-weight: 500;
  padding-top: 20px !important;
  padding-bottom: 20px !important;
}

.loginImageStyle {
  height: 100% !important;
  width: 100% !important;
}
.formPadding {
  padding-left: 18%;
}
.forgotPasswordStyle {
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 15 px;
  line-height: 16px;
  text-align: center;
  color: rgba(0, 0, 0, 0.6);
}
.marginTopStyle {
  margin-top: 10% !important;
}
.LoginButtonStyle {
  width: 61%;
  margin-left: 19%;
}
.scrollClass {
  -ms-overflow-style: none;
  scrollbar-width: none;
}
.scrollClass::-webkit-scrollbar {
  display: none;
}
