.lifetimeAnalyticsContainer {
    height: 20vh;
    width: 75%; 
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-direction: column;
    background: rgba(25, 221, 255, 0.1)
} 

.analyticsButton {
    background-color: #246bd6 !important;
    width: 100%;
    padding-top: 10px;
    padding-bottom: 10px;
    border: none;
    color: white;
    font-size: medium;
  }

.backgroundColor {
    background: rgba(25, 221, 255, 0.1)
}

.lifeTimeAnalyticsText {
    font-size: 20px;
    font-weight: bold;
}

.activityText {
    font-size: 20px;
    font-weight: bold;
}

.greenColor {
    height: 8px;
    width: 8px;
    background-color: rgb(57, 224, 155);
    margin-right: 8px;
    border-radius: 50%;
}

.purpleColor {
    height: 8px;
    width: 8px;
    background-color: rgb(117, 81, 233);
    margin-right: 8px;
    border-radius: 50%;

}


.blueColor {
    height: 8px;
    width: 8px;
    background-color: rgb(11, 175, 255);
    margin-right: 8px;
    border-radius: 50%;

}

.fontSize12 {
    font-size: 13px;
}

.boldClass {
    font-weight: bolder;
}

.widthClass {
    width: 75%
}

.lifetimeAnalyticsInnerContainer {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    width: 50%;
} 


.activityOuterContainer {
    width: 75%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    
}

.cardsContainer {
    width: 75%;
}

.socialIconAnalytics {
    height: 30px;
    width: 30px;
    margin-right: 10px
  }

@media (max-width: 428px) {
 .widthClass {
     width: 100%
 }

 .lifetimeAnalyticsContainer {
    width: 100%; 
} 

.lifetimeAnalyticsInnerContainer {
    justify-content: space-around;
    width: 75%;
} 

.activityOuterContainer{
    width: 90%;
}
.cardsContainer {
    width: 90%;
}


}