.componentHeightSignup {
  height: 100vh;
}
.imgBackgroundColorSignup {
  background-color: #f2c4ce;
}

.mainBackground
{
    background-color:#246BD61A ;
height: 100vh;
}
.signinButton {
  color: white;
  border-radius: 5px;
  width: 100%;
  padding-top: 10px;
  padding-bottom: 10px;
  background-color: #246bd6;
  border: white;
  float: right;
  margin-top: 10px;
}
.signInCointaner {
  padding-top: 20px;
}
.signUp {
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 36px;
  line-height: 48px;
  text-align: center;
  color: rgba(0, 0, 0, 0.6);
}
.labelStyleSignup {
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: rgba(0, 0, 0, 0.6);
}
.labelStyleSignupMobile {
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    font-size: 8px;
    line-height: 24px;
    color: rgba(0, 0, 0, 0.6);
  }
.inputFieldSignup {
  background: #f4f4f5;
  border-radius: 30px;
  height: 65px;
}

.inputFieldSignupMobile {
    background: #f4f4f5;
    border-radius: 30px;
    height: 50px;
  }
.submitButtonSignup {
  background-color: #246bd6 !important;
  border-radius: 30px;
  width: 100%;
  padding-top: 20px;
  padding-bottom: 20px;
  border: none;
  color: white;
  font-size: large;
}
.submitButtonSignup :disabled {
  /* background-color: #246bd6 !important; */
  background-color: aqua;
  color: black;
}

.recapchaStyle {
  margin-left: -20%;
}
.signupStyle {
  height: 100%;
  width: 100%;
}
.marginTopStyles {
  margin-top: 8% !important;
}
.eyeIconStyleSignUp {
  position: absolute;
  margin-top: -45px;
  right: 10% !important;
  /* float: right; */
  font-size: 30px;
  opacity: 0.3;
}
.checkboxStyle
{
    margin-top: 5px;
}
.eyeIconStyleSign {
    position: absolute;
    margin-top: -5%;
    right: 25% !important;
    font-size: 30px;
    opacity: 0.3;
  }
.scrollClass {
  -ms-overflow-style: none;
  scrollbar-width: none;
}
.scrollClass::-webkit-scrollbar {
  display: none;
}

.termStyleSignup {
    font-family: Roboto;
    font-style: normal;
    font-size: 14px;
    line-height: 24px;
    color: rgba(0, 0, 0, 0.6);
  }
  .termStyleSignupMobile {
      font-family: Roboto;
      font-style: normal;
      font-size: 8px;
      line-height: 24px;
      color: rgba(0, 0, 0, 0.6);
    }
