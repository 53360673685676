.phoneBgg {
  background-image: url("../../assets//images/Mobile_bg_img.png");
  height: 100vh;
  background-repeat: no-repeat;
}
.marginLeftMObileItem {
  margin-left: 6% !important;
}

.socialScrollableDiv {
  overflow: auto;
  height: 60vh;
  background-color: #ffffff !important;
}

.socialLinksScrollableDiv {
  overflow: auto;
  height: 75vh;
  background-color: #ffffff !important;
}

.socialLinksCardStyle {
  background: #ffffff;
  border-radius: 12px;
  margin-top: 10px;
}
.socialSwitch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 33px;
}

.socialSwitch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.socialSlider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.socialSlider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .socialSlider {
  background-color: #2196f3;
}

input:focus + .socialSlider {
  box-shadow: 0 0 1px #2196f3;
}

input:checked + .socialSlider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

.socialSlider.round {
  border-radius: 34px;
}

.socialSlider.round:before {
  border-radius: 50%;
}
