.loginImageStyle {
  height: 100% !important;
  width: 100% !important;
}
.inputFieldStyle {
  border-style: ridge;
  border-radius: 30px;
  background: #f4f4f5;
  height: 60px !important;
  width: 100%;
  border: 1px solid #ced4da;
  padding: 10px 0.75rem 0rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  /* text-align: center; */
  color: #495057;
}
.inputFieldStyleMobile {
    border-style: ridge;
    border-radius: 30px;
    background: #f4f4f5;
    height: 50px !important;
    width: 100%;
    border: 1px solid #ced4da;
    padding: 10px 0.75rem 0rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    /* text-align: center; */
    color: #495057;
  }
  .featureCardStyleEditProfile{
  background: #ffffff;
  box-shadow: 0px 10px 20px 15px rgb(119 122 127 / 10%);
  border-radius: 12px;
  margin-top: 30px;
  width: 38%;}
.componentHeight {
  height: 100vh;
}
.marginTop10 {
  margin-top: 10%;
}
.scrollClass {
  -ms-overflow-style: none;
  scrollbar-width: none;
}
.scrollClass::-webkit-scrollbar {
  display: none;
}


.cancelButton {
  background-color: #D10000 !important;
  border-radius: 10px;
  width: 60%;
  padding-top: 15px;
  padding-bottom: 15px;
  padding-top: 15px;
  padding-bottom: 15px;
  border: none;
  color: white;
  font-size: medium;
  margin-bottom: 15px;
}

.headingSubscription {
  font-size: 20px;
  font-weight: bold;
  text-align: center;
}

.width60 {
  width: 60%;
}

.perMonthStyle{
  font-size: 20px;
  font-weight: bold;
  color: #246bd6 !important;
}