.analyticsStyle {
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 24px;
  letter-spacing: 0.04em;
  color: #19ddff;
}
.MobileLogoStyle{
  width: 100px  ;
    margin-top: -60px;
    position: absolute;
    margin-left: 377px;
}

.analyticsStyleMobile {
    font-family: Roboto;
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 24px;
    letter-spacing: 0.04em;
    color: #19ddff;
    text-align: center;
  }
.marginLeftLinks {
  margin-left: 30px;
}

.viewsClicksStyle {
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  letter-spacing: 0.04em;
  color: #246bd6;
  padding-top: 10px;
}
.widthButton {
  width: 15% !important;
}

.viewsClicksNumericStyle {
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  letter-spacing: 0.04em;
  color: #19ddff;
}

.addNewLinkButton {
  color: white;
  width: 100%;
  padding-top: 15px;
  padding-bottom: 15px;
  background: #246bd6;
  border-radius: 4px;
}

.clickButtonText {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  text-align: center;
  color: #246bd6;
  padding-top: 30px;
}
.dropdownStyle {
  margin-right: 40%;
}
.phoneBg {
  /* background-image: url("../../assets/images/mobilephone_bg_image.svg"); */
  background-image: url("../../assets/images/Mobile_bg_img.png");
  height: 100vh;
  background-repeat: repeat-x;
}

.scrollableDiv {
  overflow: auto;
  height: 55vh;
  background-color: #ffffff !important;
}


.linksCardStyle {
  background: #ffffff;
  border-radius: 12px;
  margin-top: 10px;
}
.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 33px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.countsColor
{
    color:#246bd6;
}
.trashColor
{
color: #FF0000 !important
}
input:checked + .slider {
  background-color: #2196f3;
}

input:focus + .slider {
  box-shadow: 0 0 1px #2196f3;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}
.marginRightStyle {
  margin-right: 5% !important;
}
.marginLStyle {
  margin-left: 5% !important;
}
.marginLeftMObileItem {
  margin-left: 5% !important;
}

/* .mainDivScroll {
  margin-top: 0px !important;
  margin-bottom: 0px !important;
  margin-left: 5% !important;
  margin-right: 100px !important;
} */

.phoneLinks {
  display: flex;
  justify-content: center;
  position: relative;
}

.inputFieldStyleLink {
  border: 1px solid #D3D3D3;
  width: 75%;
  border-radius: 7px;
}

.clicksStyleLink {
   font-size: 0.75em;
  }