.featuresStyle {
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 36px;
  line-height: 48px;
  text-align: center;
  color: rgba(0, 0, 0, 0.6);
}
.createAccountStyle {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 17px;
  text-align: center;
  color: rgba(0, 0, 0, 0.6);
  padding-top: 5px;
}

.featureCardStyle {
  background: #ffffff;
  box-shadow: 0px 10px 20px 15px rgba(119, 122, 127, 0.1);
  border-radius: 12px;
  margin-top: 30px;
}

.featuresFont
{
    font-size: 13px;
    font-weight: 500;
}

.boxStyle {
  background: rgba(128, 107, 231, 0.1);
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.boxStyleMobile {
    background: rgba(128, 107, 231, 0.1);
    border-radius: 8px;
    display: flex;
    /* flex-direction: column; */
    justify-content: center;
    align-items: center;
    width: 100% !important;
  }

.companiesStyle {
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: -0.291429px;
  color: rgba(0, 0, 0, 0.6);
  text-align: left;
}
.price {
  font-style: normal;
  font-weight: bold;
  font-size: 40px !important;
  line-height: 48px;
  text-align: center;
  color: #246bd6 !important;
}
.mainBackground
{
  background-color:rgba(25, 221, 255, 0.1);
height: 100vh;
}
.time {
    font-style: normal;
    font-weight: bold;
    font-size: 20px !important;
    line-height: 48px;
    text-align: center;
    color: #246bd6 !important;
  }

.internalBox
{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}  

.featureImageStyle {
  height: 100%;
  width: 100%;
}

.continueButton {
  background-color: #246bd6 !important;
  border-radius: 30px;
  width: 100%;
  padding-top: 15px;
  padding-bottom: 15px;
}
.scrollClass {
  -ms-overflow-style: none;
  scrollbar-width: none;
}
.scrollClass::-webkit-scrollbar {
  display: none;
}
@media (max-width: 768px) {
  .priceStyle {
    font-style: normal;
    font-size: 20px;
    line-height: 28px;
    text-align: center;
    color: #246bd6 !important;
    padding-top: 20px !important;
  }
  .continueButton {
    margin-bottom: 10% !important;
  }
}
/* @media (max-width: 375px) {
  .priceStyle {
    font-style: normal;
    font-size: 20px !important;
    line-height: 28px !important;
    text-align: center;
    color: #246bd6 !important;
    padding-top: 20px !important;
  }
} */
