.pinkBackgroundColor {
  background-color: #f35a7c;
}
.inputFieldStyle {
  background: #f4f4f5;
  border-radius: 30px;
  height: 55px;
  padding-bottom: 9px !important;
}
.inputFieldStyleMobile {
    background: #f4f4f5;
    border-radius: 30px;
    height: 50px;
    padding-bottom: 9px !important;
  }
.pageHeight {
  height: 100vh;
}
.submitButton {
  background-color: #246bd6 !important;

  border-radius: 30px;
  width: 100%;
  padding-top: 15px;
  padding-bottom: 15px;
}
.personalInfoHeading {
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 36px;
  line-height: 48px;
  text-align: center;
  color: rgba(0, 0, 0, 0.6);
}
.personalInfoLabel {
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: rgba(0, 0, 0, 0.6);
}
.formStyle {
  margin-left: 20%;
  padding-top: 10%;
}
.formStyleMobile {
    padding-top: 10%;
  }
.mainBackground
{
  background-color:#246BD61A;
height: 100vh;
}
.imageStyle {
  height: 100%;
  width: 100%;
}
.submitButtonStyle {
  background-color: #806be7;
  border-radius: 30px;
  width: 100% !important;
  height: 60px;
  border: none;
  color: wheat;
  margin-left: 4% !important;
  margin-top: 2%;
}
.scrollClass {
  -ms-overflow-style: none;
  scrollbar-width: none;
}
.scrollClass::-webkit-scrollbar {
  display: none;
}
