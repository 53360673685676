.billingDetailStyle {
  font-style: normal;
  font-weight: 500;
  font-size: 36px;
  line-height: 48px;
  width: 75%;
  text-align: center;
  color: rgba(0, 0, 0, 0.6);
}

.rowStyle {
  margin-left: 0px !important;
  margin-right: 0px !important;
}
.mainBackground
{
  background-color:rgba(25, 221, 255, 0.1);
height: 100vh;
}
.cardNumberStyle {
  border-style: ridge;
  border-radius: 30px;
  background: #f4f4f5;
  width: 75%;
  height: 70px;
  border: 1px solid #ced4da;
  /* padding: 10px 0.75rem 0rem 0.75rem; */
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  text-align: center;
  color: #495057;
}
.cardNumberStyleMobile {
    border-style: ridge;
    border-radius: 30px;
    background: #f4f4f5;
    width: 100%;
    height: 45px;
    border: 1px solid #ced4da;
    padding: 10px 0.75rem 0rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    text-align: center;
    color: #495057;
  }
.billingLabelStyle {
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 48px;
  text-align: center;
  color: rgba(0, 0, 0, 0.6);
}
.expiryDateStyle {
  border-style: ridge;
  border-radius: 30px;
  background: #f4f4f5;
  height: 40px;
  width: 100%;
  border: 1px solid #ced4da;
  padding: 10px 0.75rem 0rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  text-align: center;
  color: #495057;
}
.ccvStyle {
  border-style: ridge;
  border-radius: 30px;
  background: #f4f4f5;
  height: 40px;
  width: 100%;
  border: 1px solid #ced4da;
  padding: 10px 0.75rem 0rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  text-align: center;
  color: #495057;
}
.securityStyle {
  color: #495057;
  font-size: 13px;
}
.scrollClass {
  -ms-overflow-style: none !important;
  scrollbar-width: none !important;
}
.scrollClass::-webkit-scrollbar {
  display: none !important;
}
.mainn {
  background-color: aqua;
}

@media (max-width: 768px) {
  .billingStyle {
    margin-left: 24px;
  }
}
@media (max-width: 375px) {
  .billingStyle {
    margin-left: 24px;
  }
}
