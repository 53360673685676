@media (min-width: 768px) {
  .outerContainer {
    background-color: #ffffff !important;
    min-height: 370px;
    max-height: 370px;
  }

  .iconStyle {
    height: 120px !important;
    width: 120px !important;
  }

  .featureHeading {
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 24px;

    letter-spacing: 0.5px;

    color: #246bd6;
  }

  .featureDetail {
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    /* or 171% */

    color: rgba(0, 0, 0, 0.6);
  }
}

@media (max-width: 768px) {
  .outerContainer {
    background-color: #ffffff !important;
    min-height: 370px;
    max-height: 370px;
  }

  .iconStyle {
    display: flex;
    justify-content: center;
    width: 100% !important;
    height: 100% !important;
  }

  .featureHeading {
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 24px;
    text-align: center;
    letter-spacing: 0.5px;

    color: #246bd6;
  }

  .featureDetail {
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    text-align: center;
    font-size: 16px;
    line-height: 24px;
    /* or 171% */

    color: rgba(0, 0, 0, 0.6);
  }
}
