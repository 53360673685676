.logo {
  font-family: Roboto;
  font-style: normal;
  font-weight: 300;
  font-size: 36px;
  line-height: 48px;

  letter-spacing: 0.04em;
}
.colr1 {
  color: #806be7;
}
.navButtonColr {
  background-color: #246BD6;
  color: white;
}
.ButtonColr {
  border-color: #246bd6;
  color: #246bd6;

  /* background-color: ; */
  background-color: rgba(243, 90, 124, 0.1);
}
.NavColr {
  background-color: #ECFBFF
}
.navMarginLeft {
  margin-left: 9% !important;
}
.navMrarginRight {
  margin-right: 10%;
}
.mainBackground
{
    background-color: #19DDFF;
}
