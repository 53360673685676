.componentHeight {
  height: 100vh;
}

.forgetCointanerStyle {
  padding-top: 75px;
}
.forgetHeadingStyle {
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 36px;
  line-height: 48px;
  text-align: center;
  color: rgba(0, 0, 0, 0.6);
}
.labelStyle {
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: rgba(0, 0, 0, 0.6);
}
.inputFieldStyle {
  background: #f4f4f5;
  border-radius: 30px;
}
.inputFieldStyleMobile {
    background: #f4f4f5;
    border-radius: 30px;
    height: 50px;
    display: flex;
    justify-content: center;
  }
.submitButtonStyle {
  background-color: #246bd6 !important;
  border-radius: 30px;
  width: 63% !important;
  height: 60px;
  border: none;
  color: wheat;
  margin-left: 4% !important;
  margin-top: 2%;
}
.scrollClass {
  -ms-overflow-style: none;
  scrollbar-width: none;
}
.scrollClass::-webkit-scrollbar {
  display: none;
}
.loginImageStyle {
  height: 100%;
  width: 100%;
}
.formPadding {
  padding-left: 18%;
}
.forgetmarginTopStyle {
  margin-top: 23% !important;
}
