.socialIconStyle {
  width: 50px !important;
  height: 50px !important;
}

.socialMobileContainer {
  overflow: auto;
  position: absolute;
  top: 120px;
  width: 45% !important;
  margin: 0 auto;
  height: 370px;
}

.socialMobileContainer::-webkit-scrollbar {
  display: none;
}
