.headingStyles {
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 48px;
  color: #246bd6;
  margin-bottom: 2%;
}
.subHeadingTop {
  margin-left: 8%;
}
.collapseWidth {
  width: 95%;
}
.headingStylesMobile {
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 48px;
  color: #246bd6;
  text-align: center;
  margin-bottom: 2%;
}
.objectFitClass {
  object-fit: contain;
}
.subHeadingMains {
  text-align: center;
}
.blue {
  color: #246bd6;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100%;
}
.featureFonts {
  font: normal normal bold 16px Roboto !important;
}
.colorBlack {
  color: black;
}
.subscriptionPackageColor {
  color: #246bd6;
  display: flex;
  justify-content: center;
  font: normal normal 700 14px !important;
  text-align: center;
}
.pinkColor {
  color: #19ddff;
}
.faqHeading {
  font: normal normal 700 48px Roboto !important;
  line-height: 60px;
  color: #246bd6;
}
.pricingRange {
  color: #246bd6;
  font-size: 38px;
  text-align: center;
}
.pricingRangeNumber {
    color: #246bd6;
    font-size: 30px;
    text-align: center;
  }
.greyColor {
  /* background-color: rgba(25, 221, 255, 0.05); */
  background: #246bd6;
}
.greyColors {
  background-color: rgba(25, 221, 255, 0.05);
  /* background: #246BD6; */
}
.faqBox {
  margin-left: 11%;
  margin-right: 11%;
}
.questionsList {
  margin-left: 4.5%;
  margin-right: 4.5%;
}
.purpleColor {
  color: #806be7;
}
.blackColor {
  color: rgba(0, 0, 0, 0.6);
}
.boxPadding {
  padding-left: 10%;
  padding-right: 10%;
}
.headingStyle {
  font-family: Roboto;
  font-style: normal;
  font-weight: 300;
  font-size: 18px;
  color: #246bd6;
}
.headingStyleMobile {
  font-family: Roboto;
  font-style: normal;
  font-weight: 300;
  font-size: 18px;
  color: #246bd6;
  text-align: left;
}
.paragraphStyleTop {
  font-family: Roboto;
  font-style: normal;
  font-weight: 1000;
  font-size: 18px;
  color: #246bd6;
  text-align: left;
}
.paragraphStyleTopMobile {
  font-family: Roboto;
  font-style: normal;
  font-weight: 1000;
  font-size: 18px;
  color: #246bd6;
  text-align: center;
}
.iconStyle {
  height: 20px;
  width: 20px;
}
.heading2Style {
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  margin-left: 8%;
}
.heading2StyleMobile {
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  display: flex;
  justify-content: center;
  width: 100%;
}
.desFont {
  font-size: 1.15rem;
}
.pricingTypeFont {
  font-size: 20px;
  font-weight: bold;
}
.communityStyle {
  background-color: rgba(25, 221, 255, 0.1);
  border-radius: 4px;
  margin-left: 10%;
  margin-right: 10%;
}
.margintop {
  margin-top: 5%;
}
.mainBox{
padding-top: 4rem !important;
}
.marginLeft {
  margin-left: 10%;
}
.marginRight {
  margin-right: 10%;
}
.marginNeg {
  margin-top: -6%;
}
.marginLeftStyle {
  margin-left: 5% !important;
}
.marginUnlimitedStyle {
  margin-left: 8% !important;
}
.marginUnlimitedStyleMobile {
    margin-left: 4% !important;
  }
.marginMobileLeft {
  margin-left: 7% !important;
}
.marginMobileRight {
  margin-right: 7% !important;
}
/* @media (max-width: 768px) {
  .IconTextStyle {
    margin-left: 7% !important;
    width: 90%;
  }
}
@media (min-width: 769px) {
  .IconTextStyle {
    margin-left: 7% !important;
    width: 90%;
  }
}
@media (min-width: 1300px) {
  .IconTextStyle {
    margin-left: 4% !important;
    width: 90%;
  }
} */
@media (min-width: 1101px) {
  .IconTextStyle {
    margin-left: 65px !important;
    width: 90%;
  }
}

@media (max-width: 1100px) {
  .IconTextStyle {
    width: 85%;
    margin-left: 65px !important;
  }
}

@media (max-width: 768px) {
  .IconTextStyle {
    width: 82%;
    margin-left: 65px !important;
  }
}

@media (max-width: 660px) {
  .IconTextStyle {
    width: 78%;
    margin-left: 65px !important;
  }
}

@media (max-width: 430px) {
  .IconTextStyle {
    width: 78%;
    margin-left: 22px !important;
  }
}


.footerHeadingStyle {
  font-family: Roboto;
  font-style: normal;
  font-weight: 300;
  font-size: 36px;
  line-height: 48px;
  letter-spacing: 0.04em;

  color: white;
}
.footerHeadingStyleMobile {
  font-family: Roboto;
  font-style: normal;
  font-weight: 300;
  font-size: 36px;
  line-height: 48px;
  text-align: center;
  color: white;
}
.imgLengthStyle {
  width: 100%;
  padding-bottom: 5px;
}
.footerText {
  color: white;
  font: normal normal normal 14px Roboto !important;
  line-height: 10px;
}
.footerTextMobile {
    color: white;
    font: normal normal normal 8px Roboto !important;
    line-height: 10px;
  }
.divider {
  border: 2px solid rgba(36, 107, 214, 0.2) !important;
}
.footerStyle {
  font-family: Roboto;
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 24px;

  color: white;
}

.footerStyleMobile {
  font-family: Roboto;
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: white;
}
.cardButtonStyle {
  background: #246bd6;
  border-radius: 4px;
  color: white;
  width: 75%;
  height: 150%;
  border-color: white;
}
.cardShadow {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}
.imgHeight {
  height: 20vh;
}

.pricingBlueContainerHeight {
  height: 35vh;
}

.imgStyle {
  width: 100%;
}
.userInputStyle {
  width: 34%;
  height: 60px;
  margin-left: 27px;
  border-color: #246bd6;
  color: #246bd6;
}
.userButtonStyle {
  width: 50%;
  height: 60px;
  margin-left: 5px;
  border-color: #246bd6;
  background-color: #246bd6;
  color: white;
  border-radius: 6px;
}
.cardStyle {
  border-color: #19ddff;
  border-width: 10px;
}

@media (max-width: 375px) {
  .userimgStyle {
    width: 75%;
  }
  .mobImgStyle {
    width: 100%;
  }
  .dblock {
    display: block;
  }
  div.ImgOrder {
    order: 2;
  }

  div.ParaOrder {
    order: 1;
  }
  .iconMargin {
    margin-left: 10px;
  }
  .footerIconStyle {
    display: flex;
    align-items: center;
  }

  .imgWidth {
    width: 500px !important;
    height: 30vh !important;
  }
  .HeadingSize {
    font-size: 24px !important;
  }
  .pading {
    padding: 15px;
  }
  .margin {
    margin-left: 12%;
  }
  .marginR {
    margin-right: 10px;
  }
  .marginL {
    margin-top: -22px;
  }
  .ObjFit {
    object-fit: cover;
  }
  .justifyContent {
    justify-content: center;
  }
  .NegMargin {
    margin-left: -24px;
  }
}
@media (max-width: 768px) {
  .userimgStyle {
    width: 75%;
  }
  .justifyContent {
    justify-content: center;
  }

  .mobImgStyle {
    width: 100% !important;
    display: flex;
    justify-content: center;
  }
  .getStartedFields {
    display: flex;
    justify-content: center;
  }
  .textHeading {
    text-align: center;
  }

  .faqText {
    text-align: left;
  }
  .userButtonStyle {
    width: 80%;
    height: 60px;
    margin-left: 5px;
    border-color: #246bd6;
    background-color: #246bd6;
    color: white;
    border-radius: 6px;
  }
  .d_block {
    display: block;
  }
  .footerIconStyle {
    display: flex;
    align-items: center;
    /* width: 100%; */
  }
  div.ImgOrder {
    order: 2;
  }
  div.ParaOrder {
    order: 1;
  }

  .imgWidth {
    width: 100% !important;
    height: 50vh;
  }
  .pading {
    padding: 15px;
  }
  .margin {
    margin-left: 12%;
  }
  .marginR {
    margin-right: 10px;
  }

  .CardMtop {
    margin-top: 24px;
  }
  .iconMargin {
    margin-left: 10px;
  }

  .NegMargin {
    margin-left: -24px;
  }
}

.userInputStyleMobile {
  width: 50%;
  height: 60px;
  border-color: #246bd6;
  color: #246bd6;
}

.pricingIcon {
  position: absolute;
  top: -55px;
}

.cardDiv {
  position: relative;
}

.featuresDiv {
  background-color: rgba(36, 107, 214, 0.05);
}

.featureHeadingStyle {
  font-style: normal;
  font-weight: bold;
  font-size: 48px;
  line-height: 56px;
  text-align: center;
  color: #246bd6;
}

.featureDetailDiv {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  letter-spacing: 0.5px;
  color: rgba(0, 0, 0, 0.6);
  padding-left: 10pt;
  padding-right: 10pt;
}

.footerIcon {
  width: 30px !important;
  height: 30px !important;
}

.footerIconTwitter {
  width: 30px !important;
  height: 30px !important;
}

.footerIconYtube {
  width: 40px !important;
  height: 40px !important;
}

.footerIconFacebook {
  width: 30px !important;
  height: 30px !important;
}

.pricingHeadingStyle {
  font-style: normal;
  font-weight: bold;
  font-size: 48px;
  line-height: 56px;
  text-align: center;
  color: #246bd6;
  margin-bottom: 12px;
}

.pricingTextColor {
  color: #19ddff;
  font-weight: bold;
  /* font-size: 12px; */
}
.exampleHeading {
  font-weight: bold !important;
}
.faqTextColor {
  color: #19ddff;
  text-align: left;
}
.priceBox {
  margin-top: 29px;
}
.checkSigns {
  margin-top: 4px !important;
}

.contactStyle {
  display: flex;
    /* justify-content: flex-start; */
    width: 100%;
    flex-direction: column;
}

@media (min-width: 1300px) {
    .cardContainerStyle{
      width: 67%;
    }
  }
@media (min-width: 1900px) {
  .contactStyle {
  display: flex;
    justify-content: flex-start;
    width: 52%;
  }
  .cardContainerStyle{
    width: 55%;
  }
}
@media (min-width: 2200px) {
  .contactStyle {
    display: flex;
    justify-content: flex-start;
    width: 59%;
  }
  .cardContainerStyle{
    width: 50%;
  }
}
