.componentHeight {
  height: 100vh;
}

.resetCointanerStyle {
  padding-top: 75px;
}
.resetHeadingStyle {
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 36px;
  line-height: 48px;
  text-align: center;
  color: rgba(0, 0, 0, 0.6);
}

.labelStyle {
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: rgba(0, 0, 0, 0.6);
}
.inputFieldStyle {
  background: #f4f4f5;
  border-radius: 30px;
}
.submitButtonStyle {
  background-color: #806be7;
  border-radius: 30px;
  width: 63%;
  height: 55px;
  border: none;
  color: white;
}

.loginImageStyle {
  height: 100%;
  width: 100%;
}
.formPadding {
  padding-left: 18%;
}
.scrollClass {
  -ms-overflow-style: none;
  scrollbar-width: none;
}
.scrollClass::-webkit-scrollbar {
  display: none;
}
