.addLinkCard {
  /* filter: drop-shadow(0px 4px 20px rgba(51, 51, 51, 0.08)); */
  border-radius: 12px;
}

.verticalLine {
  border-left: 4px solid black;
}

.titleStyle {
  border: 1px solid #D3D3D3;
  border-radius: 7px;
}

.addButton {
  background-color: #806be7;
  width: 100%;
  padding-top: 5px;
  padding-bottom: 5px;
  color: white;
  border-radius: 4px;
}

.spinnerPadding {
  margin-top: 15px;
}
.dotsStyle {
  height: 4vh;
  cursor: grab;
  
}

/* .vertical {
  border-left: 5px solid black;
  height: 120px; */
/* } */
.dropdownStyle {
  background-color: transparent;
  color: grey;
}

.disableDiv
{
    pointer-events: none;
    opacity:0.3;
    cursor: pointer;
}