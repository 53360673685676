.thankyouImgStyle {
  border-radius: 50%;
}
.thankyouHeadingStyle {
  font-family: Roboto;
  font-style: normal;
  font-weight: 200;
  font-size: 48px;
  line-height: 56px;
  text-align: center;

  color: #246db6;
}
.mainBackground
{
  background-color:rgba(25, 221, 255, 0.1);
height: 100vh;
}
.submitThankyouButton {
  background-color: #246db6;
  border-radius: 30px;
  width: 50%;
  padding-top: 15px;
  padding-bottom: 15px;
}
.featureImageStyle {
  height: 100%;
  width: 100%;
}
.scrollClass {
  -ms-overflow-style: none;
  scrollbar-width: none;
}
.scrollClass::-webkit-scrollbar {
  display: none;
}
